import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import LoaderComponenet from '../components/common/Loader';

import { PrivateRoute } from './PrivateRoute';
import { useLocalStorage } from './useLocalStorage';


const NotFound = lazy(() => import('../components/common/404'));

const Apply = lazy(() => import('../components/Apply'));
const Register = lazy(() => import('../components/Register'));
const Attend = lazy(() => import('../components/CheckIn'));
const Welcome = lazy(() => import('../components/Welcome'));
const Login = lazy(() => import('../components/Login'));
const Dashboard = lazy(() => import('../components/Dashboard'));
const Questionnaire = lazy(() => import('../components/Questionnaire'));
const Admin = lazy(() => import('../components/Admin'));

const CheckIn = lazy(() => import('../components/CheckIn'));

const RouteSetup = () => {

  return (
    <Suspense fallback={<LoaderComponenet />}>
      <Routes>
        <Route
          path="/"
          element={
            // <PrivateRoute>
            <Dashboard />
            // </PrivateRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <Admin />
          }
        />

        <Route path="/register" element={<CheckIn />} />

        <Route path="*" element={<NotFound />} />
        {/* <Route path="/login" element={<Login />} /> */}
      </Routes>
    </Suspense>
  );
};

export default RouteSetup;
